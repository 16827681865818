import { init as initDataLayer, dataLayer } from './globals/datalayerPusher';

/**
 * desktop only scripts
 */
// if (matchMedia('only screen and (max-width: 767px)').matches === false) {
// Google Publisher Tag
// new DynamicScript('https://www.googletagservices.com/tag/js/gpt.js', { async: true });
// }

function pushGtmVariables() {
    const gtmElements = document.querySelectorAll('[id^=gtm][data-page]');
    if (gtmElements.length) {
        const gtmVariables = { event: 'gtm_push' };

        for (let i = 0; i < gtmElements.length; i++) {
            const gtmId = gtmElements[i].getAttribute('id');
            gtmVariables[gtmId] = gtmElements[i].dataset.page;
        }

        dataLayer.customEvent.push(gtmVariables);
    }
}
function pushEvents() {
    const eventElements = document.querySelectorAll('[id^=ecom_gtm],[id^=dl_gtm]');
    if (eventElements.length) {
        for (let i = 0; i < eventElements.length; i++) {
            let event = null;
            const element = eventElements[i];
            event = JSON.parse(element.dataset.page);

            if (event) {
                dataLayer.customEvent.push({ event });
            }
        }
    }
}

function pushGlobalTracking() {
    dataLayer.customEvent.push({
        event: 'mtamInit',
        ...window.tracking,
    });
}

initDataLayer();
pushGtmVariables();
pushEvents();
pushGlobalTracking();
